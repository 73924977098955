import React, { FC, ReactNode, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Icon } from '@components/icons';
import { createPortal } from 'react-dom';
import useClickOutside from '@hooks/helpers/useClickOutside';

interface IProps {
    children: ReactNode;
    variant?: 'error' | 'warning' | 'success';
    className?: string;
    isUpdate?: boolean;
    onUpdate?: (val: any) => void;
    updateOptions?: { label: string | ReactNode; value: any }[];
}

const cx = classNames.bind(styles);

const Status: FC<IProps> = ({
    children,
    variant,
    className,
    isUpdate,
    onUpdate,
    updateOptions
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);
    const dropdownRef = useRef(null);

    const showDropdown = () => {
        if (!dropdownRef.current) return;
        //@ts-ignore
        const triggerRect = ref.current.getBoundingClientRect();

        // Calculate the position of the dropdown
        const top = triggerRect.bottom + window.scrollY;
        const left = triggerRect.left + window.scrollX;

        // Position the dropdown
        //@ts-ignore
        dropdownRef.current.style.top = `${top}px`;
        //@ts-ignore
        dropdownRef.current.style.position = `absolute`;
        //@ts-ignore
        dropdownRef.current.style.display = `flex`;
        //@ts-ignore
        dropdownRef.current.style.left = `${left - 100}px`;
        setIsOpen(true);
    };

    const hideDropdown = () => {
        //@ts-ignore
        dropdownRef.current.style.display = `none`;
        setIsOpen(false);
    };
    const rootStyles = useMemo(
        () => cx([styles.wrapper, className, variant]),
        [className, variant]
    );

    return (
        <div className={styles.container}>
            <div
                className={rootStyles}
                ref={ref}
                onClick={() => {
                    (updateOptions?.length || 0) > 0 && (isOpen ? hideDropdown() : showDropdown());
                }}>
                {children} {isUpdate && (updateOptions?.length || 0) > 0 && <Icon name="edit" />}
            </div>
            {updateOptions &&
                createPortal(
                    <div
                        className={`status_dropdown ${isOpen ? 'isOpen' : ''}`}
                        ref={dropdownRef}
                        onMouseLeave={hideDropdown}>
                        {updateOptions?.map((item, i) => {
                            return (
                                <button
                                    key={i}
                                    onClick={() => {
                                        onUpdate && onUpdate(item.value);
                                        setIsOpen(false);
                                    }}>
                                    {item.label}
                                </button>
                            );
                        })}
                    </div>,
                    document.body
                )}
        </div>
    );
};

export default Status;

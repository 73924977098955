import React, { FC, useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { supportedLanguages } from '@const/index';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './sidebar';
import Button from '@components/common/button';
import { Icon } from '@components/icons';
import { useGlobalContext } from '@hooks/context/useGlobalContext';
import classNames from 'classnames/bind';
import ActivityServiceProvider from '../../service_providers/activity_provider';

interface IProps {
    className?: string;
}

const cx = classNames.bind(styles);
const GlobalProvider: FC<IProps> = () => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { navigateButton } = useGlobalContext();

    useEffect(() => {
        if (
            localStorage.getItem('language') &&
            supportedLanguages.includes(localStorage.getItem('language') || 'en')
        ) {
            i18n.changeLanguage(localStorage.getItem('language') || 'en');
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const contentStyles = useMemo(
        () => cx([styles.content, { isBack: !!navigateButton.label }]),
        [navigateButton]
    );

    return (
        <ActivityServiceProvider>
            <div className={styles.wrapper}>
                <Sidebar />
                <div className={contentStyles}>
                    {navigateButton.label && (
                        <Button
                            className={styles.back_button}
                            onClick={() => navigate(navigateButton.route)}>
                            {' '}
                            <Icon name="arrow_left" /> {navigateButton.label}
                        </Button>
                    )}
                    <Outlet />
                </div>
            </div>
        </ActivityServiceProvider>
    );
};

export default GlobalProvider;

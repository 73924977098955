import React, { FC, useEffect } from 'react';
import DataInfo from '@components/data_info';
import FormField from '@components/form_field';
import { useForm } from '@hooks/helpers/useForm';
import Input from '@components/common/input';
import { useLocales } from '@hooks/helpers/useLocales';
import styles from './styles.module.scss';
import { TConsumer } from '@xeppt/xeppt-sdk/types';

interface IProps {
    consumer?: TConsumer;
}

const KYCSection: FC<IProps> = ({ consumer }) => {
    const form = useForm({});
    const { labelLocale } = useLocales();

    useEffect(() => {
        if (consumer) {
            form.reset({
                firstName: consumer?.profile?.firstName,
                lastName: consumer?.profile?.lastName,
                verificationRef: consumer?.verificationRef
            });
        }
    }, [consumer]);

    return (
        <div className={styles.wrapper}>
            <DataInfo label="KYC" form={form}>
                <FormField
                    name="firstName"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('first_name')} />;
                    }}
                />
                <FormField
                    name="lastName"
                    renderComponent={(props) => {
                        return <Input full {...props} label={labelLocale('last_name')} />;
                    }}
                />
                <FormField
                    name="verificationRef"
                    renderComponent={(props) => {
                        return <Input full {...props} label="KYC ID" />;
                    }}
                />
            </DataInfo>
        </div>
    );
};

export default KYCSection;

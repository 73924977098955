import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import HeadItem from '@components/table/head_item';
import Checkbox from '@components/common/checkbox';
import Typography from '@components/common/typography';

export type TColumn = {
    label: string;
    key?: string;
    width?: number;
    isSort?: boolean;
    isOverflow?: boolean;
    render?: (val: any, row: any) => ReactNode | string;
    isRightAlignment?: boolean;
    filterOptions?: {
        value: string;
        label: string;
    }[];
    isSingleFiler?: boolean;
};

export type TRow = Record<string, any>;

export type TFilter = {
    [key: string]: any[];
};

export type TSort = {
    key: string;
    value?: 'ASC' | 'DESC';
};

interface IProps {
    columns: TColumn[];
    rows?: TRow[];
    onRowClick?: (data: TRow) => void;
    onSortChange?: (key: string, value: 'ASC' | 'DESC') => void;
    onFilterChange?: (key: TFilter) => void;
    sort?: TSort;
    filter?: TFilter;
    isActions?: boolean;
    selectKey?: string;
    totalRows?: number;
    selected?: string[];
    onSelectedChange?: (val: string[]) => void;
    isSingleSelect?: boolean;
    emptyDescription?: string;
    onLoadData?: () => void;
}

const cx = classNames.bind(styles);

const Table: FC<IProps> = ({
    columns,
    rows,
    onRowClick,
    onSortChange,
    sort,
    filter,
    onFilterChange,
    selectKey,
    emptyDescription,
    selected,
    onSelectedChange,
    isSingleSelect,
    totalRows,
    onLoadData,
    isActions = false
}) => {
    const loader = useRef(null);
    const [trigger, setTrigger] = useState(0);
    const rowStyle = useMemo(() => cx([{ isOnclick: !!onRowClick }]), [onRowClick]);

    function getValueByPath(obj: any, path?: string) {
        if (path) {
            return path.split('.').reduce((acc, part) => acc && acc[part], obj);
        }
        return '';
    }

    const handleSortChange = (key?: string) => {
        if (onSortChange && key) {
            if (sort?.key === key) {
                if (sort?.value === 'ASC') {
                    onSortChange(key, 'DESC');
                } else {
                    onSortChange(key, 'ASC');
                }
            } else {
                onSortChange(key, 'DESC');
            }
        }
    };

    const handleFilterChange = (key: string, value: string, column: TColumn) => {
        if (onFilterChange) {
            if (filter?.[key] && !column?.isSingleFiler) {
                if (filter[key].includes(value)) {
                    onFilterChange({
                        ...filter,
                        [key]: filter[key].filter((item) => item !== value)
                    });
                } else {
                    onFilterChange({
                        ...filter,
                        [key]: [...filter[key], value]
                    });
                }
            } else {
                onFilterChange({
                    ...filter,
                    [key]: [value]
                });
            }
        }
    };

    const handleChangeSelect = (value: string) => {
        if (onSelectedChange && selected && selectKey) {
            if (selected?.includes(value)) {
                onSelectedChange(selected?.filter((item) => item !== value));
            } else {
                if (isSingleSelect) {
                    onSelectedChange([value]);
                } else {
                    onSelectedChange([...selected, value]);
                }
            }
        }
    };

    useEffect(() => {
        const loadItems = () => {
            if (totalRows && onLoadData && rows && totalRows > rows.length && rows.length > 0) {
                onLoadData();
            }
        };

        loadItems();
    }, [trigger]);

    const handleObserver = useCallback(
        (entries: any) => {
            const target = entries[0];
            if (target.isIntersecting) {
                setTrigger((prev) => prev + 1);
            }
        },
        [rows]
    );

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: '20px',
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) {
            observer.observe(loader.current);
        }
    }, [handleObserver]);

    return (
        <div className={styles.wrapper}>
            <table className={styles.table}>
                <thead className={cx([styles.header, { isActions, isSelect: !!selectKey }])}>
                    <tr>
                        {selectKey && (
                            <th>
                                <div>Select</div>
                            </th>
                        )}
                        {columns.map((column, i) => {
                            return (
                                <HeadItem
                                    key={i}
                                    handleFilterChange={handleFilterChange}
                                    column={column}
                                    filter={filter}
                                    handleSortChange={handleSortChange}
                                    sort={sort}
                                    isActions={isActions && columns.length - 1 === i}
                                />
                            );
                        })}
                    </tr>
                </thead>
                <tbody className={styles.body}>
                    {rows?.map((row, i) => {
                        return (
                            <tr
                                key={i}
                                className={rowStyle}
                                onClick={() => onRowClick && onRowClick(row)}>
                                {selectKey && (
                                    <td key={i}>
                                        <div className={styles.select_wrapper}>
                                            <Checkbox
                                                checked={selected?.includes(row?.[selectKey])}
                                                onClick={() =>
                                                    row?.[selectKey] &&
                                                    handleChangeSelect(row?.[selectKey])
                                                }
                                            />
                                        </div>
                                    </td>
                                )}
                                {columns.map((column, i) => {
                                    const columnStyle = {
                                        textAlign: column.isRightAlignment
                                            ? 'right'
                                            : column?.key && row?.[column?.key]
                                              ? 'left'
                                              : 'center',
                                        width: column.width,
                                        whiteSpace: column?.isOverflow && 'nowrap',
                                        overflow: column?.isOverflow && 'hidden',
                                        textOverflow: column?.isOverflow && 'ellipsis'
                                    };
                                    return (
                                        <td key={i}>
                                            {/*//@ts-ignore*/}
                                            <div style={columnStyle}>
                                                {column?.render
                                                    ? column.render(
                                                          getValueByPath(row, column?.key),
                                                          row
                                                      )
                                                    : getValueByPath(row, column?.key) || '-'}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {(rows || []).length === 0 && emptyDescription && (
                <div className={styles.empty_wrapper}>
                    <Typography variant="body3">{emptyDescription}</Typography>
                </div>
            )}
            <div ref={loader} />
        </div>
    );
};

export default Table;

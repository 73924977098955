export const getYears = (count: number, future: boolean) => {
    const finishYear = new Date().getFullYear() + (future ? 100 : 0),
        years = [];
    let startYear = finishYear - (count + (future ? 100 : 0));
    while (startYear <= finishYear) {
        years.push(startYear++);
    }
    return years;
};

export const getDisablePast = (): Date => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear());

    return maxDate;
};

export const getDisableFeature = (): Date => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear());

    return maxDate;
};

import React, { useEffect } from 'react';
import Input from '@components/common/input';
import Phone from '@components/phone';
import Select from '@components/common/select';
import { useLocales } from '@hooks/helpers/useLocales';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import FormField from '@components/form_field';
import useModalContext from '@hooks/context/useModalContext';
import { emailRegex } from '@const/regex';
import { EUserRole } from '@xeppt/xeppt-sdk/types';
import { getDefaultPhoneCode } from '@utils/index';

const CreateAdminModal = () => {
    const { labelLocale } = useLocales();
    const { handleChangeDisable, isDisableSubmit, changeModalData } = useModalContext();
    const form = useForm({});
    const watch = form.watch();

    useEffect(() => {
        if (!form.formState.isValid !== isDisableSubmit) {
            handleChangeDisable(!form.formState.isValid);
        }
    }, [watch]);

    useEffect(() => {
        changeModalData(form.getValues());
    }, [isDisableSubmit]);

    useEffect(() => {
        form.setValue('phoneCode', getDefaultPhoneCode());
    }, []);

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">Create admin user</Typography>
            <FormProvider {...form}>
                <div className={styles.content}>
                    <div className={styles.row}>
                        <FormField
                            name="firstName"
                            renderComponent={(props) => (
                                <Input full label={labelLocale('first_name')} {...props} />
                            )}
                            rules={{ required: true }}
                        />
                        <FormField
                            name="lastName"
                            renderComponent={(props) => (
                                <Input full label={labelLocale('last_name')} {...props} />
                            )}
                            rules={{ required: true }}
                        />
                    </div>
                    <FormField
                        name="email"
                        renderComponent={(props) => (
                            <Input full label={labelLocale('email')} {...props} />
                        )}
                        rules={{
                            required: true,
                            validate: (val: string) => emailRegex.test(val) || 'Invalid email'
                        }}
                    />
                    <Phone selectSize="small" label={labelLocale('phone')} />
                    <FormField<string | number>
                        name="role"
                        renderComponent={(props) => (
                            <Select
                                full
                                label={labelLocale('role')}
                                {...props}
                                items={[
                                    { label: 'Admin', value: EUserRole.ADMIN as number },
                                    {
                                        label: 'Sales Manager',
                                        value: EUserRole.SALES_MANAGER as number
                                    },
                                    {
                                        label: 'Customer Service',
                                        value: EUserRole.CUSTOMER_SERVICE as number
                                    }
                                ]}
                            />
                        )}
                        rules={{ required: true }}
                    />
                </div>
            </FormProvider>
        </div>
    );
};

export default CreateAdminModal;

import React, { useEffect, useState } from 'react';
import { useLogs } from '@hooks/api/useLogs';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import RangeDatePicker from '@components/range_date_picker';
import Button from '@components/common/button';
import Table, { TFilter } from '@components/table';
import Drawer from '@components/drawer';
import Input from '@components/common/input';
import Accordion from '@components/common/accordin';
import MultiSelect from '@components/common/multi_select';
import { logsLevels } from '@const/index';
import { getLogsLevel, getLogsStatusColor } from '@utils/index';
import moment from 'moment';
import Link from '@components/common/link';
import { routes } from '@const/routes';
import Status from '@components/common/status';
import Dropdown from '@components/common/dropdown';
import { ELogLevel } from '@xeppt/xeppt-sdk/types';
import { useDebounceValue } from 'usehooks-ts';
import { TUserLog } from '@xeppt/xeppt-sdk/types/admin';
import { useNavigate } from 'react-router-dom';
import { getDisableFeature } from '@utils/date';

const LogsLayout = () => {
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
    const { handleGetLogs, logs, onChangeFilter, logsData, logsFilter } = useLogs({});
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [requestSearch, setRequestSearch] = useDebounceValue('', 500);

    useEffect(() => {
        setRequestSearch(search);
    }, [search]);

    useEffect(() => {
        if (requestSearch.length >= 3) {
            onChangeFilter({ consumerId: requestSearch });
        } else {
            onChangeFilter({ consumerId: undefined });
        }
    }, [requestSearch]);

    const columns = [
        {
            label: 'Date and Time',
            key: 'createdAt',
            isSort: true,
            render: (value: string) => moment(value).format('MM.DD.YYYY HH:mm')
        },
        {
            label: 'Consumer ID',
            key: 'consumerId',
            render: (value: string) => <Link to={`${routes.consumers}/${value}`}>{value}</Link>
        },
        {
            label: 'Status',
            key: 'level',
            render: (value: ELogLevel) => (
                <Status variant={getLogsStatusColor(value)}>{getLogsLevel(value)}</Status>
            )
        },
        { label: 'Message', key: 'message', width: 400 },
        {
            label: 'Actions',
            key: 'id',
            render: (value: string, data: TUserLog) => (
                <Dropdown
                    items={[
                        ...(data?.consumerId
                            ? [
                                  {
                                      label: 'Go to user',
                                      onClick: () => {
                                          navigate(`${routes.consumers}/${data?.consumerId}`);
                                      }
                                  }
                              ]
                            : [])
                    ]}
                />
            )
        }
    ];

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Logs</Typography>
                    <Typography>{logsData?.total} records found</Typography>
                </div>
                <div className={styles.actions}>
                    <RangeDatePicker
                        values={[logsFilter.dateFrom?.toString(), logsFilter.dateTo?.toString()]}
                        onChange={(val) =>
                            onChangeFilter({
                                dateFrom: val?.[0] ? new Date(val?.[0]) : undefined,
                                dateTo: val?.[1] ? new Date(val?.[1]) : undefined
                            })
                        }
                        maxDate={getDisableFeature()}
                    />
                    <Button
                        size="medium"
                        variant="primary"
                        leftIcon="filter_bars"
                        onClick={() => setIsFilterDrawerOpen(true)}>
                        Filter
                    </Button>
                </div>
            </div>
            <Table
                columns={columns}
                rows={logs}
                sort={{ key: 'createdAt', value: logsFilter.order }}
                onSortChange={() => {
                    onChangeFilter({
                        order: logsFilter.order === 'DESC' ? 'ASC' : 'DESC'
                    });
                }}
                filter={logsFilter as TFilter}
                onLoadData={() => handleGetLogs({ isNew: false, filter: logsFilter })}
                totalRows={logsData?.total || 0}
                onFilterChange={(val) => onChangeFilter(val)}
                emptyDescription="Loggs list is empty"
            />
            <Drawer
                className={styles.drawer}
                isOpen={isFilterDrawerOpen}
                onClose={() => setIsFilterDrawerOpen(false)}>
                <Input
                    full
                    leftIcon="search"
                    value={search}
                    onChange={(val) => setSearch(val)}
                    placeholder="Search by consumer ID"
                />
                <Accordion label="Date and Time" isDefaultOpened>
                    <RangeDatePicker
                        full
                        values={[logsFilter.dateFrom?.toString(), logsFilter.dateTo?.toString()]}
                        onChange={(val) =>
                            onChangeFilter({
                                dateFrom: val?.[0] ? new Date(val?.[0]) : undefined,
                                dateTo: val?.[1] ? new Date(val?.[1]) : undefined
                            })
                        }
                        maxDate={getDisableFeature()}
                    />
                </Accordion>
                <Accordion label="Log type" isDefaultOpened>
                    <MultiSelect
                        full
                        label="Type"
                        values={logsFilter.level || []}
                        onChange={(val) => {
                            onChangeFilter({ level: val as ELogLevel[] });
                        }}
                        items={logsLevels}
                    />
                </Accordion>
            </Drawer>
        </div>
    );
};

export default LogsLayout;

import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '@hooks/context/useGlobalContext';
import { routes } from '@const/routes';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Tabs from '@components/tabs';
import PersonalSection from '@sections/consumer/personal';
import BusinessSection from '@sections/consumer/business';
import CardsSection from '@sections/consumer/cards';
import TransactionsSection from '@sections/consumer/transactions';
import KYCSection from '@sections/consumer/kyc';
import RequestsSection from '@sections/consumer/requests';
import LogsSection from '@sections/consumer/logs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useConsumers } from '@hooks/api/useConsumers';
import CardTransactionsSection from '@sections/consumer/card_transactions';
import Link from '@components/common/link';
import { Icon } from '@components/icons';
import Switch from '@components/common/switch';
import moment from 'moment-timezone';

const tabs = [
    {
        label: 'Personal Information',
        value: 'info'
    },
    {
        label: 'Business Information',
        value: 'business'
    },
    {
        label: 'Cards',
        value: 'cards'
    },
    {
        label: 'Transaction History',
        value: 'transaction'
    },
    {
        label: 'KYC',
        value: 'kyc'
    },
    {
        label: 'Requests',
        value: 'requests'
    },
    {
        label: 'Logs',
        value: 'logs'
    }
];

const ConsumerLayout = () => {
    const [isUseConsumerTimezone, setIsUseConsumerTimezone] = useState(false);
    const { handleChangeBackLabel } = useGlobalContext();
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const { id } = useParams<{ id: string }>();
    const cardId = new URLSearchParams(window.location.search).get('cardId');

    const { consumer } = useConsumers({
        isInitialConsumer: true
    });

    useEffect(() => {
        handleChangeBackLabel({ label: 'Consumers List', route: routes.consumers });
        return () => {
            handleChangeBackLabel({
                label: '',
                route: ''
            });
        };
    }, [id]);

    const navigate = useNavigate();
    const location = useLocation();

    const removeQueryParams = (params: string[]) => {
        const searchParams = new URLSearchParams(location.search);
        params.forEach((param) => searchParams.delete(param));
        navigate({
            pathname: location.pathname,
            search: searchParams.toString()
        });
    };

    useEffect(() => {
        if (activeTab !== 'cards') {
            removeQueryParams(['cardId']);
        }
    }, [activeTab]);

    const renderContent = () => {
        switch (activeTab) {
            case 'info':
                return <PersonalSection consumer={consumer} />;
            case 'business':
                return <BusinessSection consumer={consumer} />;
            case 'cards':
                return cardId ? <CardTransactionsSection cardId={cardId} /> : <CardsSection />;
            case 'transaction':
                return <TransactionsSection />;
            case 'kyc':
                return <KYCSection consumer={consumer} />;
            case 'requests':
                return <RequestsSection />;
            case 'logs':
                return <LogsSection />;
            default:
                return null;
        }
    };

    const onChangeTimezoneUsage = () => {
        localStorage.setItem('isUseConsumerTimezone', JSON.stringify(!isUseConsumerTimezone));
        setIsUseConsumerTimezone((state) => !state);
    };

    useEffect(() => {
        setIsUseConsumerTimezone(
            JSON.parse(localStorage.getItem('isUseConsumerTimezone') || 'false')
        );
    }, []);

    useEffect(() => {
        if (isUseConsumerTimezone && consumer) {
            moment.tz.setDefault(consumer?.profile?.timezone);
        } else {
            moment.tz.setDefault();
        }
    }, [isUseConsumerTimezone, consumer]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div>
                    <Link to={routes.consumers} className="back">
                        <Icon name="arrow_left" width={16} height={16} />
                        Back
                    </Link>
                    <div className={styles.text_wrapper}>
                        <Typography variant="h4">Consumer Profile</Typography>
                    </div>
                </div>
                <div className={styles.timezone}>
                    <Switch checked={isUseConsumerTimezone} onClick={onChangeTimezoneUsage} />
                    <div>Use consumer timezone</div>
                </div>
            </div>
            <Tabs
                onChange={(val) => setActiveTab(val)}
                tabs={tabs.filter((item) => {
                    if (item.value === 'business') {
                        //@ts-ignore
                        return !!consumer?.business;
                    }
                    return true;
                })}
                activeTab={activeTab}
            />
            <div className={styles.content}>{renderContent()}</div>
        </div>
    );
};

export default ConsumerLayout;

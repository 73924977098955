import React from 'react';
import illustration from '@svg/illustrations/404.svg';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useNotFound } from '@hooks/helpers/useNotFound';

const NotFoundPage = () => {
    const { handleNotFound } = useNotFound();
    return (
        <div className={styles.wrapper}>
            <img src={illustration} alt="page not found" />
            <Typography className={styles.big_text}>404</Typography>
            <Typography variant="body1">Sorry, page not found</Typography>
            <Button
                className={styles.button}
                variant="primary"
                size="normal"
                onClick={() => handleNotFound({ isRedirectBack: true, isRedirectNotFound: false })}>
                Back
            </Button>
        </div>
    );
};

export default NotFoundPage;

import CreateAdminModal from '../UI/modals/create_admin';
import UserActivityModal from '../UI/modals/user_activity';
import { IModalComponentProps } from '../context/modal_context';

type ModalType = {
    modalId: string;
    confirmLabel?: string;
    cancelLabel?: string;
    isHeader?: boolean;
    isFooter?: boolean;
    variant: 'medium' | 'small' | 'custom' | 'large';
    Component: React.FC<IModalComponentProps> | null;
};

const modalIds = {
    CREATE_ADMIN_USER: 'CREATE_ADMIN_USER',
    USER_ACTIVITY_MODAL: 'USER_ACTIVITY_MODAL'
};

const createAdminModal: ModalType = {
    modalId: modalIds.CREATE_ADMIN_USER,
    confirmLabel: 'create',
    cancelLabel: 'cancel',
    isHeader: true,
    isFooter: true,
    variant: 'small',
    Component: CreateAdminModal
};

const userActivityModal: ModalType = {
    modalId: modalIds.USER_ACTIVITY_MODAL,
    variant: 'small',
    Component: UserActivityModal
};

const modals: ModalType[] = [createAdminModal, userActivityModal];

export { modals, modalIds };

import React from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { routes } from '@const/routes';
import { useForm } from '@hooks/helpers/useForm';
import FormField from '@components/form_field';
import { zodResolver } from '@hookform/resolvers/zod';
import { signInValidation } from '@helpers/validation_objects/signin';
import { defaultSignInData } from '@const/default_form_data';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiAuthService, apiUserService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { EUserRole } from '@xeppt/xeppt-sdk/types';
import { useUserContext } from '@hooks/context/useUserContext';

const SignInLayout = () => {
    const { t } = useTranslation('sign_in');
    const { validationLocale, requestSuccessLocale, labelLocale, requestErrorLocale } =
        useLocales();
    const { handleLogout } = useUserContext();
    const navigate = useNavigate();
    const {
        control,
        formState: { isValid },
        getValues
    } = useForm({
        defaultValues: defaultSignInData,
        resolver: zodResolver(
            signInValidation({
                messages: {
                    email: validationLocale('email')
                }
            })
        )
    });

    const { handleRequest: handleSubmitForm, isLoading } = useApiMutation({
        method: () => {
            const { email, password } = getValues();
            return apiAuthService.login({
                emailOrPhone: email,
                password
            });
        },
        onSuccess: () => {
            apiUserService.profile().then(({ role }) => {
                if (role === EUserRole.REGULAR_USER) {
                    handleLogout();
                    toast.error(t('requests.error.role'));
                } else {
                    toast.success(requestSuccessLocale('sign_in'));
                    navigate(routes.transactions);
                }
            });
        },
        onError: requestErrorLocale
    });

    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <div className={styles.form}>
                <FormField
                    control={control}
                    name="email"
                    renderComponent={(props) => (
                        <Input {...props} full label={labelLocale('email')} name="email" />
                    )}
                />
                <FormField
                    control={control}
                    name="password"
                    renderComponent={(props) => (
                        <Input
                            {...props}
                            full
                            type="password"
                            name="password"
                            label={labelLocale('password')}
                        />
                    )}
                />
            </div>
            <Button
                onClick={handleSubmitForm}
                isLoading={isLoading}
                disabled={!isValid}
                variant="primary"
                size="normal">
                {t('submit')}
            </Button>
        </div>
    );
};
export default SignInLayout;

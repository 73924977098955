import React, { FC, ReactNode, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import useClickOutside from '@hooks/helpers/useClickOutside';
import { Icon } from '@components/icons';
import { createPortal } from 'react-dom';

interface IProps {
    className?: string;
    items: {
        onClick: () => void;
        label: ReactNode | string;
    }[];
}

const cx = classNames.bind(styles);

const Dropdown: FC<IProps> = ({ className, items }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);
    const dropdownRef = useRef(null);

    const showDropdown = () => {
        if (!dropdownRef.current) return;
        //@ts-ignore
        const triggerRect = ref.current.getBoundingClientRect();

        // Calculate the position of the dropdown
        const top = triggerRect.bottom + window.scrollY;
        const left = triggerRect.left + window.scrollX;

        // Position the dropdown
        //@ts-ignore
        dropdownRef.current.style.top = `${top}px`;
        //@ts-ignore
        dropdownRef.current.style.position = `absolute`;
        //@ts-ignore
        dropdownRef.current.style.display = `flex`;
        //@ts-ignore
        dropdownRef.current.style.left = `${left - 100}px`;
        setIsOpen(true);
    };

    const hideDropdown = () => {
        //@ts-ignore
        dropdownRef.current.style.display = `none`;
        setIsOpen(false);
    };

    const toggleDropdown = () => {
        if (isOpen) {
            hideDropdown();
        } else {
            showDropdown();
        }
    };

    useClickOutside({
        ref: dropdownRef,
        callback: hideDropdown,
        event: 'mousedown'
    });

    return (
        <div className={cx([styles.wrapper, className])} ref={ref}>
            <Icon name="more_vertical" onClick={toggleDropdown} />
            {createPortal(
                <div className={`dropdown_style ${isOpen ? 'isOpen' : ''}`} ref={dropdownRef}>
                    {items.map((item, i) => {
                        return (
                            <button
                                key={i}
                                onClick={() => {
                                    item.onClick();
                                    toggleDropdown();
                                }}>
                                {item.label}
                            </button>
                        );
                    })}
                </div>,
                document.body
            )}
        </div>
    );
};

export default Dropdown;

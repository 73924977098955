import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '@hooks/context/useGlobalContext';
import { routes } from '@const/routes';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Tabs from '@components/tabs';
import PersonalSection from '@sections/admin/personal';
import { useParams } from 'react-router-dom';
import { useAdmins } from '@hooks/api/useAdmins';
import Link from '@components/common/link';
import { Icon } from '@components/icons';
import LogsSection from '@sections/consumer/logs';

const tabs = [
    {
        label: 'Personal Information',
        value: 'info'
    },
    {
        label: 'Logs',
        value: 'logs'
    }
];

const AdminLayout = () => {
    const { handleChangeBackLabel } = useGlobalContext();
    const [activeTab, setActiveTab] = useState(tabs[0].value);
    const { id } = useParams<{ id: string }>();

    const { admin } = useAdmins({
        isInitialAdmin: true
    });

    useEffect(() => {
        handleChangeBackLabel({ label: 'Consumers List', route: routes.consumers });
        return () => {
            handleChangeBackLabel({
                label: '',
                route: ''
            });
        };
    }, [id]);

    const renderContent = () => {
        switch (activeTab) {
            case 'info':
                return <PersonalSection admin={admin} />;
            case 'logs':
                return <LogsSection />;
            default:
                return null;
        }
    };

    return (
        <div className={styles.wrapper}>
            <Link to={routes.admins} className="back">
                <Icon name="arrow_left" width={16} height={16} />
                Back
            </Link>
            <div className={styles.header}>
                <div className={styles.text_wrapper}>
                    <Typography variant="h4">Admin Profile</Typography>
                </div>
            </div>
            <Tabs
                onChange={(val) => setActiveTab(val)}
                tabs={tabs.filter((item) => {
                    if (item.value === 'business') {
                        //@ts-ignore
                        return !!consumer?.business;
                    }
                    return true;
                })}
                activeTab={activeTab}
            />
            <div className={styles.content}>{renderContent()}</div>
        </div>
    );
};

export default AdminLayout;

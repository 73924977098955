import React, { FC, ReactNode } from 'react';
import { useUserActivity } from '@hooks/helpers/useUserActivity';

interface IProps {
    children: ReactNode;
}

const ActivityServiceProvider: FC<IProps> = ({ children }) => {
    useUserActivity();

    return <>{children}</>;
};

export default ActivityServiceProvider;

import { useState } from 'react';
import { apiAdminService } from '@api';
import { EConsumerStatus } from '@xeppt/xeppt-sdk/types/consumer';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { useParams } from 'react-router-dom';
import { TConsumerLite } from '@xeppt/xeppt-sdk/types';
import { useApiMutation } from '@hooks/api/useApiMutation';

interface IProps {
    isInitialConsumers?: boolean;
    isInitialConsumer?: boolean;
    isInitialConsumerCard?: boolean;
    isInitialConsumerCards?: boolean;
}

export const useConsumers = ({
    isInitialConsumers = false,
    isInitialConsumer = false,
    isInitialConsumerCards = false
}: IProps) => {
    const [consumersCursor, setConsumersCursor] = useState<Date>();
    const [consumers, setConsumers] = useState<TConsumerLite[]>([]);
    const { id } = useParams();
    const [consumersFilter, setConsumersFilter] = useState<{
        status?: EConsumerStatus[];
        limit?: number;
        search?: string;
        order: 'ASC' | 'DESC';
    }>({
        status: undefined,
        limit: 100,
        order: 'ASC'
    });

    const { data: consumersData, handleRequest: refetchConsumers } = useApiQuery({
        method: (request: any) => {
            return apiAdminService.getConsumers(
                {
                    status: request?.filter?.status,
                    order: request?.filter?.order,
                    search: request?.filter?.search
                },
                {
                    cursor: request?.isNew ? undefined : consumersCursor,
                    limit: request?.filter?.limit
                }
            );
        },
        onSuccess: (data, request?: any) => {
            if (consumersCursor !== data.cursor && !request?.isNew) {
                setConsumers((state) => [...state, ...data.data]);
                setConsumersCursor(data.cursor);
            } else {
                setConsumers(data.data || []);
                setConsumersCursor(data.cursor);
            }
        },
        isInitialRequest: isInitialConsumers
    });

    const { data: consumer } = useApiQuery({
        method: () => apiAdminService.getConsumerById(id || ''),
        condition: !!id,
        deps: [id],
        isInitialRequest: isInitialConsumer
    });

    const {
        data: consumerCard,
        handleRequest: getConsumerCardById,
        handleRemoveResponse: handleRemoveCard
    } = useApiQuery({
        method: (id?: string) => {
            if (id) {
                return apiAdminService.getConsumerCardById(id || '');
            } else {
                return Promise.reject('');
            }
        },
        isInitialRequest: false
    });

    const { data: consumerCards } = useApiQuery({
        method: () => apiAdminService.getConsumerCards({ id: id || '' }),
        condition: !!id,
        deps: [id],
        isInitialRequest: isInitialConsumerCards
    });

    const { handleRequest: handleDeleteUser } = useApiMutation({
        method: (id: string) => apiAdminService.deleteUser(id),
        onSuccess: () => {
            refetchConsumers({ isNew: true, filter: consumersFilter });
        }
    });

    const handleChangeConsumersFilter = (data: Partial<typeof consumersFilter>) => {
        setConsumersCursor(undefined);
        setConsumersFilter((state) => ({ ...state, ...data }));
        refetchConsumers({ isNew: true, filter: { ...consumersFilter, ...data } });
    };

    return {
        consumers,
        consumer,
        consumerCards,
        refetchConsumers,
        consumerCard,
        consumersFilter,
        consumersData,
        getConsumerCardById,
        handleChangeConsumersFilter,
        handleRemoveCard,
        handleDeleteUser
    };
};
